import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import MainSectionZakaznickaZona from "../components/zakaznicka-zona/mainSection.js";

import "../styles/programovanie-na-mieru.scss";
import ContactUs from "../components/contactUs";
import ProgramovanieSolutionBox from "../components/programovanie-na-mieru/programovanieSolutionBox";
import ZonaFaqQuestion from "../components/zakaznicka-zona/zonaFaqQuestion.js";
import SliderSectionZona from "../components/zakaznicka-zona/sliderSection.js";
import References from "../components/homepage/references.js";
import ZonaClankySlider from "../components/zakaznicka-zona/zonaClankySlider";
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image";

import problemImg from "../images/vyvoj-aplikacii/vyvoj-aplikacii-a-programov-pochopenie-problemu.jpg";
import viziaImg from "../images/vyvoj-aplikacii/vyvoj-aplikacii-programov-vizia-a-misia.jpg";
import tvorbaImg from "../images/vyvoj-aplikacii/programovanie-aplikacii-ios-android-dizajn-grafika-na-mieru.jpg";
import monitorivanieImg from "../images/vyvoj-aplikacii/programovanie-na-mieru-aplikacie-vyvoj-monitoring-podpora-a-servis.jpg";

import skusenostiIcon from "../images/vyvoj-aplikacii/programovanie-aplikacii-skusenosti.svg";
import vasenIcon from "../images/vyvoj-aplikacii/programovanie-ios-android-vasen.svg";
import partnerIcon from "../images/vyvoj-aplikacii/vyvoj-aplikacii-partner.svg";
import cenaIcon from "../images/vyvoj-aplikacii/vyvoj-aplikacii-ferova-cena.svg";
import klientIcon from "../images/vyvoj-aplikacii/programovanie-aplikacii-klient-na-prvom-mieste.svg";
import myslenieIcon from "../images/vyvoj-aplikacii/vyvoj-aplikacii-rozumne-uzitocne-efektivne.svg";
import viziaIcon from "../images/vyvoj-aplikacii/vyvoj-aplikacii-a-programov-vizia-a-misia.svg";

//import zonesColage from "../images/zakaznicka-zona/okna.jpg";

export const pageQuery = graphql`
  query {
    articles: wpCategory(slug: { eq: "programovanie-na-mieru" }) {
      posts {
        nodes {
          title
          excerpt
          slug
          featuredImage {
            node {
              altText
              title
              gatsbyImage(width: 1920)
              sourceUrl
            }
          }
        }
      }
    }
    mysqlProjects(url: { eq: "plusim" }) {
      url
      real_url
      client
      hashtag
      project_description
      case_study_title
      case_study_rok
      case_study_sluzby
      case_study_vyzva
      case_study_proces
      case_study_riesenie
      ogDescription
    }
    files: allFile(
      filter: {
        relativeDirectory: { eq: "plusim" }
        sourceInstanceName: { eq: "images-projects" }
      }
    ) {
      nodes {
        name
        publicURL
        childImageSharp {
          gatsbyImageData
        }
      }
    }
    projekty: allMysqlProjects(sort: { fields: case_study_rok, order: DESC }) {
      nodes {
        url
        client
        case_study_description
      }
    }
    logosWhite: allFile(
      filter: {
        name: { eq: "logo-white" }
        sourceInstanceName: { eq: "images-projects" }
      }
    ) {
      nodes {
        name
        relativeDirectory
        publicURL
      }
    }
  }
`;

const VyvojAplikacii = (pageQuery) => {
  const images = {};
  pageQuery.data.files.nodes.map((node) => {
    let index = node.name;
    images[index] = node;
  });

  return (
    <Layout pageName="programovanie-na-mieru-main">
      <SEO
        title="Vývoj Aplikácií a Programov na mieru"
        description="Vytvárame Android a iOS aplikácie, prispôsobené vašimi potrebám. Nezáleží na veľkosti alebo odvetví vašej firmy; ponúkame technologické riešenia, ktoré vám pomôžu rásť a prosperovať."
      />
      <MainSectionZakaznickaZona
        heading="Vývoj Aplikácií a Programov na mieru"
        id="md"
        textHtml={
          <>
            Vytvárame Android a iOS <b>aplikácie, prispôsobené</b> vašimi{" "}
            <b>potrebám</b>. Nezáleží na veľkosti alebo odvetví vašej firmy;{" "}
            <b>ponúkame </b>
            technologické riešenia, ktoré vám pomôžu <b>rásť</b> a{" "}
            <b>prosperovať</b>.
          </>
        }
      />
      <section id="what-is-client-zone">
        <ProgramovanieSolutionBox
          img={3}
          heading="Od nápadu po rast"
          text={
            <>
              Vývoj aplikácie je cestou <b>transformácie</b>. Začína to nápadom,{" "}
              <b>iskrou inovácie</b>, ktorý pretvoríme do{" "}
              <b>funkčného konceptu</b> a <b>plnohodnotnej </b>
              mobilnej <b>aplikácie</b>, či <b>programu</b>.
            </>
          }
        />
      </section>

      {/*<section id="client-zone-colage">
        <img
          className="img"
          src={zonesColage}
          alt="Zákaznícke zóny"
          
        />
      </section>*/}

      <section className="section-blog">
        <div className="banner-blog">
          <h2>
            Prečo si vybrať nás pri vývoji Android, iOS aplikácií a programov na
            mieru?
          </h2>
        </div>
      </section>
      <section id="case-study-info">
        <div class="vyzva">
          <div>
            <div class="iconContainer">
              <img
                className="img"
                src={skusenostiIcon}
                alt="Programovanie aplikácií - skúsenosti"
                title="Programovanie aplikácií - skúsenosti"
              />
            </div>
          </div>
          <div>
            <h3>
              Skúsenosti, ktoré cítiť{" "}
              <StaticImage
                className="img"
                src="../images/new-web/arrow.svg"
                alt="Šípka vpravo"
                placeholder="blurred"
              />
            </h3>
            <p>
              Máme za sebou kopu skvelých projektov. Verte nám, urobili sme to
              už predtým a vieme, čo funguje. Váš projekt bude v rukách ľudí,
              ktorí to robia so srdcom.
            </p>
          </div>
        </div>
        <div class="vyzva">
          <div>
            <div class="iconContainer">
              <img
                className="img"
                src={vasenIcon}
                alt="Programovanie iOS a Android, naša vášeň"
                title="Programovanie iOS a Android, naša vášeň"
              />
            </div>
          </div>
          <div>
            <h3>
              Naša vášeň je odbornosť{" "}
              <StaticImage
                className="img"
                src="../images/new-web/arrow.svg"
                alt="Šípka vpravo"
                placeholder="blurred"
              />
            </h3>
            <p>
              Máme najlepší tím – a to nie je len fráza. Neustále sa vzdelávame,
              aby sme vám priniesli len to najlepšie z tech sveta.
            </p>
          </div>
        </div>
        <div class="vyzva">
          <div>
            <div class="iconContainer">
              <img
                className="img"
                src={partnerIcon}
                alt="Vývoj aplikácií: partner"
                title="Vývoj aplikácií: partner"
              />
            </div>
          </div>
          <div>
            <h3>
              Nie ste len klient. Ste náš partner{" "}
              <StaticImage
                className="img"
                src="../images/new-web/arrow.svg"
                alt="Šípka vpravo"
                placeholder="blurred"
              />
            </h3>
            <p>
              K vašim snom a cieľom pristupujeme, ako keby boli naše vlastné.
              Budeme pri vás počas všetkých krokov.
            </p>
          </div>
        </div>
        <div class="vyzva">
          <div>
            <div class="iconContainer">
              <img
                className="img"
                src={cenaIcon}
                alt="Vývoj aplikácií: férová cena"
                title="Vývoj aplikácií: férová cena"
              />
            </div>
          </div>
          <div>
            <h3>
              Férová cena, žiadne prekvapenia{" "}
              <StaticImage
                className="img"
                src="../../images/new-web/arrow.svg"
                alt="Šípka vpravo"
                placeholder="blurred"
              />
            </h3>
            <p>
              Ponúkame jasné a priame ceny. Žiadne skryté poplatky, žiadne
              prekvapenia – len čistá hodnota.
            </p>
          </div>
        </div>

        <div class="vyzva">
          <div>
            <div class="iconContainer">
              <img
                className="img"
                src={klientIcon}
                alt="Programovanie aplikácií - klient na prvom mieste"
                title="Programovanie aplikácií - klient na prvom mieste"
              />
            </div>
          </div>
          <div>
            <h3>
              Ste pre nás na prvom mieste{" "}
              <StaticImage
                className="img"
                src="../../images/new-web/arrow.svg"
                alt="Šípka vpravo"
                placeholder="blurred"
              />
            </h3>
            <p>
              Chceme, aby ste sa cítili výnimočne. Naše uši sú vždy otvorené pre
              vaše nápady a otázky.
            </p>
          </div>
        </div>

        <div class="vyzva">
          <div>
            <div class="iconContainer">
              <img
                className="img"
                src={myslenieIcon}
                alt="Vývoj aplikácií: rozumné, užitočné, efektívne"
                title="Vývoj aplikácií: rozumné, užitočné, efektívne"
              />
            </div>
          </div>
          <div>
            <h3>
              Myslíme ekonomicky a účinne{" "}
              <StaticImage
                className="img"
                src="../../images/new-web/arrow.svg"
                alt="Šípka vpravo"
                placeholder="blurred"
              />
            </h3>
            <p>
              Váš rozpočet je u nás v bezpečných rukách. Nechceme plytvať ani
              minútou vášho času, ani eurami z vašej peňaženky.
            </p>
          </div>
        </div>

        <div class="vyzva">
          <div>
            <div class="iconContainer">
              <img
                className="img"
                src={viziaIcon}
                alt="Vývoj aplikácií a programov - vízia a misia"
                title="Vývoj aplikácií a programov - vízia a misia"
              />
            </div>
          </div>
          <div>
            <h3>
              Vaša vízia, naša misia{" "}
              <StaticImage
                className="img"
                src="../../images/new-web/arrow.svg"
                alt="Šípka vpravo"
                placeholder="blurred"
              />
            </h3>
            <p>
              Keď váš projekt rastie a prosperuje, sme šťastní. Spoločne
              budujeme niečo, čo má hodnotu a bude trvať roky.
            </p>
          </div>
        </div>
      </section>
      <section className="section-blog">
        <div className="banner-blog">
          <p>
            Pripojte sa k nám a urobme spolu niečo výnimočné! Ak hľadáte
            partnera, ktorý sa postará o vaše sny tak, ako by boli jeho vlastné,
            ste na správnom mieste.
          </p>
        </div>
      </section>
      <section id="online-business-solutions">
        <h2>Čo môžete očakávať od spolupráce s nami?</h2>
        <div className="solution-box loaded">
          <img
            className="img solution-img"
            src={problemImg}
            alt="Vývoj aplikácií a programov - pochopenie problému"
            title="Vývoj aplikácií a programov - pochopenie problému"
          />
          <div className="solution-description">
            <h3>Pochopenie problému</h3>
            <p>
              Nehľadáme len technické riešenia; vždy začíname tým, že sa snažíme
              <b> pochopiť</b>, čo skutočne <b>potrebujete Vy a Vaši klienti</b>
              . Pripravte sa na cestu, kde spolu objavíme jadro problému a
              načrtneme ideálny plán a jeho riešenie.
            </p>
            {/*<br />
            <p className="bold">
              Prvé stretnutie je na nás a trvá 1 – 2 hodiny
            </p>*/}
          </div>
        </div>

        <div className="solution-box loaded">
          <div className="solution-description">
            <h3>Vaša vízia, náš plán</h3>
            <p>
              Spoločne <b>definujeme</b> tú <b>najlepšiu verziu</b> vášho
              produktu a potom ju testujeme vo svete skutočných ľudí. Aby ste
              boli istí, že sme na správnej ceste,{" "}
              <b>pripravíme všetko potrebné</b> pre tím, ktorý sa pustí do
              vývoja.
            </p>
          </div>
          <img
            className="img solution-img"
            src={viziaImg}
            alt="Vývoj aplikácií a programov: Vízia a misia"
            title="Vývoj aplikácií a programov: Vízia a misia"
          />
        </div>

        <div className="solution-box loaded">
          <img
            className="img solution-img"
            src={tvorbaImg}
            alt="Programovanie aplikácií iOS a Android - dizajn a grafika na mieru"
            title="Programovanie aplikácií iOS a Android - dizajn a grafika na mieru"
          />
          <div className="solution-description">
            <h3>Tvorba, ktorá hrá na prvú ligu</h3>
            <p>
              S detailnou starostlivosťou a pochopením trhu <b>premeníme</b>{" "}
              vašu <b>víziu na produkt</b>, ktorý vaši klienti budú milovať.
              Každý detail <b>bude tvorený</b> s myšlienkou{" "}
              <b>na konečného užívateľa</b> a jeho skutočné potreby.
            </p>
          </div>
        </div>

        <div className="solution-box loaded">
          <div className="solution-description">
            <h3>Nekončíme pri štarte</h3>
            <p>
              Aj po spustení vášho produktu sa naša práca nekončí.{" "}
              <b>Budeme </b>
              sledovať, ako sa vašim klientom darí, <b>prispôsobovať sa</b> ich
              <b> potrebám</b> a <b>vylepšovať</b> produkt na základe ich
              spätnej väzby. Pretože v konečnom dôsledku je <b>úspech</b> vášho
              produktu našim <b>cieľom</b>.
            </p>
          </div>
          <img
            className="img solution-img"
            src={monitorivanieImg}
            alt="Programovanie na mieru – aplikácie ich monitoring, podpora a servis"
            title="Programovanie na mieru – aplikácie ich monitoring, podpora a servis"
          />
        </div>
      </section>

      <References />

      <ZonaClankySlider clanky={pageQuery.data.articles} />

      <section id="zonaFaq">
        <h2>
          Často kladené otázky
          <StaticImage
            className="img"
            src="../../images/new-web/arrow.svg"
            alt="Šípka vpravo"
            placeholder="blurred"
          />
        </h2>

        <p>
          Pri vývoji vývoji a programovaní Aplikácií a Programov na mieru sa
          klienti často pýtajú na mnohé otázky. Nižšie nájdete zoznam
          najčastejších otázok a ich odpovedí:
        </p>
        <br />
        <br />

        <ZonaFaqQuestion
          question="Koľko bude stáť tvorba aplikácie na mieru?"
          answer={
            <>
              Cena za programovanie na mieru závisí od komplexnosti a funkcií,
              ktoré si želáte. Avšak investícia do kvalitnej aplikácie vám v
              dlhodobom horizonte prinesie určite úžasné výsledky a návratnosť.
            </>
          }
        />

        <ZonaFaqQuestion
          question="Ako dlho trvá vývoj aplikácií pre Android alebo iOS?"
          answer={
            <>
              Čas potrebný na vývoj aplikácií závisí od jej zložitosti. Ale
              vďaka nášmu skúsenému týmu, ktorý rozumie programovaniu na mieru,
              vás uistíme, že vaša aplikácia bude pripravená v čo najkratšom
              možnom čase. Treba však rátať minimálne 2 mesiace.
            </>
          }
        />

        <ZonaFaqQuestion
          question="Ako zabezpečíte, že moja aplikácia bude jedinečná?"
          answer={
            <>
              Naše služby zahŕňajú tvorbu na mieru, čo znamená, že každá
              aplikácia, ktorú vyvíjame, je špecificky prispôsobená vašim
              potrebám a vizii.
            </>
          }
        />

        <ZonaFaqQuestion
          question="Budete podporovať obe platformy, Android a iOS?"
          answer={
            <>
              Áno, máme skúsenosti s programovaním na obidvoch platformách. Bez
              ohľadu na to, či ide o Android alebo iOS, vytvoríme pre vás
              perfektnú aplikáciu.
            </>
          }
        />

        <ZonaFaqQuestion
          question="Aký je váš prístup k zabezpečeniu aplikácie?"
          answer={
            <>
              Bezpečnosť je pre nás na prvom mieste. Vďaka našim skúsenostiam v
              oblasti programovania a tvorby na mieru zabezpečíme, že vaša
              aplikácia bude nielen funkčná, ale aj bezpečná.
            </>
          }
        />

        <ZonaFaqQuestion
          question="Ako môžeme sledovať pokrok vývoja?"
          answer={
            <>
              Náš proces výroby je transparentný. Budete informovaný o každom
              nutnom kroku a dianí počas celého procesu v pravidelných
              intervaloch. Zvyčajne sa však jedná o spoločné update video-hovory
              v priemere každé 2 týždne.
            </>
          }
        />

        <ZonaFaqQuestion
          question="Prečo by som mal vybrať práve vás?"
          answer={
            <>
              Okrem našej vášne pre programovanie a tvorbu na mieru vkladáme do
              každého projektu srdce. Chceme, aby vaša aplikácia nielen splnila
              vaše očakávania, ale aj priniesla radost vám a vašim užívateľom.
              Veríme, že partnerský vzťah a dôvera je nielen to, čo spája, ale
              je to hlavne dôvod vybrať si nás.
            </>
          }
        />
      </section>
      <ContactUs
        text={<>Máte záujem o vývoj aplikácií a programov na mieru?</>}
        button="Napíšte nám e-mail"
      />
    </Layout>
  );
};

export default VyvojAplikacii;
